import React from "react"
import Layout from "../layouts/en"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"
import Features from "../components/features"

const DsgvoPage = () => {
    return (
        <Layout>
            <Seo id="dsgvo" />
            <SimplePage title="Privacy Policy">
            
            <h2>Processing of personal data of business partners</h2>
            <p>As business partner, your following personal data will be regularly processed exclusively for business purposes:</p>

            <Features items={[
                "Name",
                "First name",
                "Company address",
                "Invoice address",
                "Delivery address",
                "Telephone number",
                "Fax number",
                "Mobile phone number (optional)",
                "E-mail address",
                "Business banking details",
            ]} />

            <p>All information is necessary for establishing contact and for proper processing of offers and orders. Point (b) of Art. 6(1) EU GDPR serves as the legal basis. For reporting to public entities, for instance the responsible financial authorities or the Federal Statistical Office, point (c) of Art. 6(1) EU GDPR serves as the legal basis. Insofar as you agree to the data processing on the basis of an approval, point (a) of Art. 6(1) EU GDPR applies. If we declare a legitimate interest in data processing, point (f) of Art. 6(1) EU GDPR applies.</p>

            <p>Transfer to third parties only takes place in the following cases:</p>

            <Features items={[
                "EDI with Eddyson, insofar as personal data exist in transaction data."
            ]} />

            <p>If necessary, we must forward information on the customer’s respective contact person (first name, surname, telephone number) to the commissioned forwarding agent.</p>
            <p>Furthermore, we are also subject to a statutory obligation to forward data to official authorities, for instance tax offices or customs offices.</p>
            <p>Data are not transferred to third countries.</p>
            <p>These master data are stored in our IT systems as long as the business relationship exists and this is stipulated by statutory legitimation. Our business partners’ data is deleted after the end of the statutory retention period, which is based on the respective data utilisation. If a customer requests a deletion or blocking, this data is immediately blocked and deleted or anonymised after the end of the statutory retention period.</p>

            <h2>Information on the name and contact data of the responsible organisation (controller)</h2>
            <p>
                Fritz Finkernagel Drahtwerk GmbH & Co. KG<br />
                Westiger Straße 120-128, 58762 Altena, Germany
            </p>

            <p>Represented by:<br />
            Managing director: Fritz Uwe Finkernagel</p>

            <p>
                Phone: +49 2352/ 703-0<br />
                Fax: +49 2352/ 75447<br />
                E-mail: <a href="mailto:info@finkernagel.com">info@finkernagel.com</a>
            </p>

            <h2>Information on the name and contact data of the responsible data protection officer </h2>

            <p>
                Stefan Käsler<br />
                Goethestraße 75<br />
                58566 Kierspe, Germany
            </p>
            <p>Phone: +49 2359 291420<br />
            E-mail: <a href="mailto:finkernagel@complere.de">finkernagel@complere.de</a></p>

            <h2>Information on the right to information</h2>
            <p>You shall be entitled at any time to receive information free of charge regarding the origin, recipient and purpose of your stored personal data.</p>
            
            <h2>Information on the right to correction/deletion or restriction of the data processing</h2>
            <p>You shall be entitled, at any time and within the context of the applicable statutory provisions, to receive information free of charge on any data stored relating to you personally as well as on their origin, their recipient and the purpose of data processing. You shall also be entitled to claim the rectification, blocking or deletion of such data. For further questions about this as well as general questions about “personal data”, you may contact us at any time under the given address.</p>
            
            <h2>Information on the right to raise objections against the data processing</h2>
            <p>Many data processing procedures are only possible with your explicit consent. You may revoke any consent already granted at any time. To do that, please send us an informal notification by email. The lawfulness of the data processing until such time as your consent is revoked shall not be affected by the revocation.</p>
            
            <h2>Information on the right to data portability</h2>
            <p>You are entitled to have any data that we automatically process based on your consent or in fulfilment of a contract handed over to you or a third party in a common, machine-readable format. Should you require direct transmission of the data to another Controller, this is only done if it is technically feasible.</p>
            
            <h2>Information on the right to complain to a supervisory authority</h2>
            <p>In the event of data privacy infringements, the data subject is entitled to file a complaint with the nearest supervisory authority. The responsible supervisory authority regarding data protection legislation for our company is the State Data Protection Authority of North Rhine-Westphalia.</p>


</SimplePage>
</Layout>
)
}

export default DsgvoPage
